import React from 'react'
import Head from 'next/head'

import NotFoundPageStyled from './styled'

class NotFoundPage extends React.Component {
  render() {
    return (
      <NotFoundPageStyled>
        <Head>
          <title>Page Not Found</title>
        </Head>
        <div className="text">404</div>
      </NotFoundPageStyled>
    )
  }
}

export default NotFoundPage
